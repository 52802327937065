export const FeatureCollection = (lot) => ({
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        name: "Lot",
      },
      geometry: {
        type: "Polygon",
        coordinates: lot,
      },
    },
  ],
});
