import React, { useRef, useEffect } from "react";
import { Search } from "react-feather";
import { Box } from "@mui/material";

import { useGlobalState } from "../context/context";

const SearchBar = () => {
  const { state, dispatch } = useGlobalState();
  const geocoderContainerRef = useRef(null);

  useEffect(() => {
    dispatch({ type: "SET_SEARCH_REF", geocoderContainerRef });

    // Remove scroll on focus behavior
    const handleFocus = () => {
      if (window.innerWidth < 900) {
        const yOffset = -10;
        const y =
          geocoderContainerRef.current?.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    geocoderContainerRef.current.addEventListener("focus", handleFocus);

    return () => {
      geocoderContainerRef.current.removeEventListener("focus", handleFocus);
    };
  }, [dispatch]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Search
        color="#C4C4C4"
        style={{ position: "absolute", zIndex: 10, margin: "13px" }}
      />
      <div ref={geocoderContainerRef} />
    </Box>
  );
};

export default SearchBar;
