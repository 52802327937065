import { Button, Typography } from "@mui/material";

import { useGlobalState } from "../context/context";
import IneligibleReasonList from "./IneligibleReasonList";

const subheadlineCopy = (state, dispatch) => {
  switch (state.appState) {
    case "QUERY":
      return null;
    case "FORM":
      // if (state.appState === 'FORM' && (state.queryResult.eligibility.overall === "YES" || state.queryResult.eligibility.overall === "MAYBE")) {
      //   <>Now see if developing your property is <strong>feasibile & <strong>profitable</strong>.</>
      // }
      if (
        state.appState === "FORM" &&
        (state.queryResult.eligibility.overall === "YES" ||
          state.queryResult.eligibility.overall === "MAYBE")
      ) {
        return (
          <>
                      <span
           style={{
                display: "block",  
                textTransform: "uppercase",
                fontSize: "14px",
                fontWeight: 700,
                marginBottom: "6px",
                letterSpacing: ".01em",
              }}>
            <br/>
              Next Step
                </span>{" "}
            <strong
           style={{
                display: "block",    
                marginBottom: ".05em",
                fontSize: "1.4em",
              }}>
              Find out your Yardsworth™
            </strong>{" "}<br/>
            Your Yardsworth™ is the estimated market value of backyard land – just like a Zestimate™ but for your yard!<br/><br/>
          Whether you'd like to sell a portion of your land or divide your lot and build on it, the Yardsworth team has you covered.<br/><br/>
                Enter your info below to have your Yardsworth™ emailed to you!
          </>
        );
      } else if (
        state.queryResult.type === "Coordinate" &&
        state.queryResult.eligibility?.mostLikely
      ) {
        return (
          <>
            But if you think your city may have fire mitigation strategies in
            place for high-fire zones{" "}
            <span
              style={{
                color: "#2576CA",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => dispatch({ type: "REVEAL_FORM" })}
            >
              click here
            </span>
            .
          </>
        );
      } else {
        return state.geocoderResult.context.find((c) =>
          c.id.includes("district")
        ).text !== "Los Angeles County" ? (
          <>
            Try searching for another address or{" "}
            <a
              style={{ color: "#2576CA" }}
              href="https://www.myhomestead.com/handbooks/sb9-handbook-for-homeowners"
            >
              click here to learn more about SB 9
            </a>
            .
          </>
        ) : (
          <>
            But you still could qualify for an ADU!
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                textTransform: "none",
                fontWeight: 700,
                borderRadius: "15px",
                marginTop: "30px",
              }}
              size="large"
              href="https://www.ottoadu.com/contact?utm_source=eligibility-tool&utm_medium=homestead&utm_campaign=yw-referral"
            >
              See ADU Eligibility
            </Button>
          </>
        );
      }
    case "FORM_SUBMIT":
      return (
        <>
          In the meantime,{" "}
          <a
            style={{ color: "#2576CA" }}
            href="https://yardsworth.com/community?utm_source=thank-you&utm_medium=form&utm_campaign=homestead"
          >
            learn more about Yardsworth's mission.
          </a>
        </>
      );
    default: {
      throw new Error(`Unhandled app state: ${state.appState}`);
    }
  }
};

const Subheadline = () => {
  const { state, dispatch } = useGlobalState();
  return (
    <>
      <Typography
        sx={{ fontSize: "18px", lineHeight: 1.2, marginBottom: "15px" }}
      >
        {subheadlineCopy(state, dispatch)}
      </Typography>
      {state.queryResult.eligibility.overall === "NO" && (
        <IneligibleReasonList />
      )}
    </>
  );
};

export default Subheadline;
