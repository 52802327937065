import React from "react";
import { InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomLabel = styled(InputLabel)(() => ({
  color: "#0b296f",
  fontWeight: 500,
  top: 3,
}));

const FormLabel = ({ label }) => {
  return (
    <CustomLabel shrink htmlFor={label} required>
      {label}
    </CustomLabel>
  );
};

export default FormLabel;
