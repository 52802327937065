import { Grid, Typography } from "@mui/material";
import React from "react";
import { ArrowUpRight } from "react-feather";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useGlobalState } from "../context/context";
import Bullets from "./Bullets";

const gradeColors = {
  A: {
    color: "#0B6F65",
    background: "#23A094",
  },
  "A-": {
    color: "#1C6F0B",
    background: "#649A59",
  },
  "B+": {
    color: "#5E6F0B",
    background: "#B0C15B",
  },
  B: {
    color: "#6F560B",
    background: "#D7B13F",
  },
  C: {
    color: "#6F1F0B",
    background: "#C17765",
  },
  D: {
    color: "#6F0B2C",
    background: "#C26584",
  },
};

const StatsPanel = () => {
  const { state } = useGlobalState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: "14px 20px 20px 20px",
        boxShadow: `0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)`,
        [theme.breakpoints.down("md")]: {
          padding: "10px 15px 15px 15px",
        },
      }}
    >
      <Grid item container alignItems="center" sx={{ marginBottom: "8px" }}>
        <Grid item sx={{ marginRight: "10px" }}>
          <img src="/images/mapbox-marker-icon.svg" alt="map marker icon" />
        </Grid>
        <Grid item sx={{ minWidth: 0, flex: 1 }} xs>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Canela",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {!matches && "SB 9 Eligibility for "}
            <span style={{ textTransform: "capitalize" }}>
              {state.queryResult.shortAddress}
            </span>
            {matches && " report"}
          </Typography>
        </Grid>
      </Grid>
      {state.queryResult.cityGrade && (
        <Grid
          item
          container
          sx={{
            backgroundColor: `${
              gradeColors[state.queryResult.cityGrade].background
            }10`,
            margin: "0 -10px 16px",
            padding: "10px",
            borderRadius: "10px",
            width: "calc(100% + 20px)",
          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                lineHeight: 1,
                color: `${gradeColors[state.queryResult.cityGrade].color}`,
              }}
            >
              {state.queryResult.cityGrade}
            </Typography>
          </Grid>
          <Grid item container direction="column" xs sx={{ marginLeft: "6px" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: ".85rem",
                lineHeight: 1.2,
                color: `${gradeColors[state.queryResult.cityGrade].color}`,
              }}
            >
              {state.queryResult.jurisdiction}
              {" SB 9 Friendliness Grade"}
            </Typography>
            <a
              href={`https://myhomestead.com/sb9-city-guide/${state.queryResult.jurisdiction
                .toLowerCase()
                .replaceAll(" ", "-")}`}
              target="_blank"
              rel="noreferrer"
              style={{
                color: `${gradeColors[state.queryResult.cityGrade].color}80`,
                display: "flex",
              }}
            >
              <Typography variant="caption" sx={{ lineHeight: 1 }}>
                Based on Homestead's SB 9 City Guides
              </Typography>
              <ArrowUpRight size={14} />
            </a>
          </Grid>
        </Grid>
      )}
      <Grid item container>
        <Bullets />
      </Grid>
    </Grid>
  );
};

export default StatsPanel;
