import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    enterTouchDelay={50}
    leaveTouchDelay={6000}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "8px",
  },
});

export default CustomTooltip;
