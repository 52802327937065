import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { GlobalProvider } from "./context/context";
import App from "./App";
import theme from "./styles/theme";

const rootEl = document.getElementById("root");
process.env.NODE_ENV !== "development" &&
  Sentry.init({
    dsn: "https://00d729a5611f4412b4973dce2014fa3c@o1138164.ingest.sentry.io/6192163",
    integrations: [new Integrations.BrowserTracing()],
  });

const render = (Component) => {
  return ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalProvider>
        <Component />
      </GlobalProvider>
    </ThemeProvider>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}
