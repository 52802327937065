import React, { useState } from "react";
import { Typography, Grid, Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useGlobalState } from "../context/context";
import IneligbleReason from "./IneligbleReason";
import FormField from "./FormField";
import { submitErrorReport } from "../context/context";

const reasonDictionary = {
  fire: {
    headline: "High Fire Zone and SB 9",
    description: (
      <>
        Properties that are located within CalFire’s “Very High Fire Severity
        Zones” are not eligible for SB 9 (unless excluded from the specified
        hazard zone by a local agency).{" "}
        <a
          style={{ color: "#2576CA" }}
          href="https://www.myhomestead.com/blog/sb9-fire-zones"
        >
          Learn more about High Fire Zones here
        </a>
        .
      </>
    ),
  },
  urban: {
    headline: "Urban Areas and SB 9",
    description:
      "Properties that are not in an urbanized area, designated by the United States Census Bureau are not not eligible for SB 9.",
  },
  flood: {
    headline: "Flood Zones and SB 9",
    description:
      "Properties that are within the 1 percent annual chance flood zone as determined by FEMA are not eligible for SB 9.",
  },
  residential: {
    headline: "Zoning and SB 9",
    description:
      "Properties must be zoned for single-family residential use in order to qualify for SB 9.",
  },
  historic: {
    headline: "Historic Properties and SB 9",
    description:
      "Properties that are located within historic districts or have been listed as a historical landmark are not eligible for SB 9.",
  },
  sqft: {
    headline: "Lot Size and SB 9",
    description:
      "Properties must be over 2,400 Sq Ft to be eligible for an SB 9  lot split. ",
  },
};

const validationSchema = Yup.object({
  email: Yup.string().email("Email is not valid").required("Required"),
});

const IneligibleReasonList = () => {
  const [submitted, setSubmitted] = useState(false);
  const { state } = useGlobalState();
  const formik = useFormik({
    initialValues: {
      email: "",
      feedback: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitted(true);
      return submitErrorReport({
        recordId: state.queryResult.recordId,
        email: values.email,
        feedback: values.feedback,
      });
    },
  });

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          variant="button"
          sx={{ color: "#878787", letterSpacing: "5%" }}
        >
          Why your property isn't qualified:
        </Typography>
      </Grid>
      <Grid
        item
        container
        sx={{
          padding: "16px",
          backgroundColor: "#FBF5EF",
          borderRadius: "20px",
        }}
      >
        {Object.entries(state.queryResult.eligibility)
          .filter(
            (entry) =>
              entry[1] === "NO" &&
              entry[0] !== "overall" &&
              entry[0] !== "allowedUseType"
          )
          .map((entry) => (
            <Grid item key={entry[0]} sx={{ marginBottom: "16px" }}>
              <IneligbleReason
                error={entry[0]}
                headline={reasonDictionary[entry[0]].headline}
                description={reasonDictionary[entry[0]].description}
              />
            </Grid>
          ))}
        <Grid
          item
          container
          direction="column"
          sx={{
            padding: "16px",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          <Grid item xs>
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {!submitted
                ? "Think we got it wrong?"
                : "Thank you! We will reach out shortly."}
            </Typography>
          </Grid>
          {!submitted && (
            <>
              <Grid item xs>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#878787",
                    lineHeight: 1.2,
                    marginBottom: "4px",
                  }}
                >
                  Let us know and we'll follow up to see if your property is
                  indeed eligible!
                </Typography>
              </Grid>

              <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                <Grid item>
                  <FormField
                    name="feedback"
                    onChange={formik.handleChange}
                    value={formik.values.feedback}
                    error={
                      formik.touched.feedback && Boolean(formik.errors.feedback)
                    }
                    helperText={
                      formik.touched.feedback && formik.errors.feedback
                    }
                    placeholder="What can be improved?"
                    fullWidth
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item container>
                  <Grid item xs sx={{ marginRight: "8px" }}>
                    <FormField
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      placeholder="Enter your email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="submit"
                      sx={{
                        textTransform: "none",
                        fontWeight: 700,
                        borderRadius: "15px",
                      }}
                      size="large"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IneligibleReasonList;
