import { Container, Grid, Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "./styles/mapbox-gl-geocoder.css";
import "./styles/overrides.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import LeftPanel from "./components/LeftPanel";
import MapPanel from "./components/MapPanel";
import StatsPanel from "./components/StatsPanel";
import { useGlobalState, queryAddress } from "./context/context";

const App = () => {
  const geocoderContainerRef = useRef();
  const { state, dispatch } = useGlobalState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (state.queryResult) {
      queryAddress(dispatch, { result: state.queryResult });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <Grid
          container
          sx={{ height: "100%", width: "100%" }}
          justifyContent="space-between"
        >
          <Grid item sm={12} md={5} sx={{ zIndex: 2, width: "100%" }}>
            <LeftPanel geocoderContainerRef={geocoderContainerRef} />
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              position: "relative",
              [theme.breakpoints.up("md")]: {
                maxHeight: "1000px",
                margin: "60px 0",
                borderRadius: "30px",
                overflow: "hidden",
                height: "calc(100vh - 120px)",
              },
              [theme.breakpoints.down("md")]: {
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                height: "240px",
              },
            }}
          >
            {matches && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, rgba(255, 252, 249, 0.36) 0%, rgba(255, 252, 249, 0.9) 83.33%)",
                }}
              />
            )}
            {!matches && state.appState === "FORM" && !state.loading && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  padding: "20px",
                }}
              >
                <StatsPanel />
              </Box>
            )}
            <MapPanel geocoderContainerRef={geocoderContainerRef} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default App;
