import { Grid, Button, Typography, Grow } from "@mui/material";
import React from "react";
import { useTheme, darken } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useGlobalState } from "../context/context";
import { ArrowLeft, ArrowRight } from "react-feather";
import Headline from "./Headline";
import Header from "./Header";
import StatsPanel from "./StatsPanel";
import Subheadline from "./Subheadline";
import SearchBar from "./SearchBar";
import LeadForm from "./LeadForm";
import PartnerCard from "./PartnerCard";

const LeftPanel = () => {
  const { state, dispatch } = useGlobalState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      direction="column"
      sx={{
        paddingTop: "100px",
        height: "100%",
        [theme.breakpoints.down("md")]: {
          paddingTop: "20px",
        },
        marginBottom: matches && state.appState === "QUERY" ? "300px" : 0,
        width: "100%",
      }}
    >
      <Grid
        item
        sx={{
          marginBottom: "40px",
          [theme.breakpoints.down("md")]: {
            marginBottom: "90px",
          },
        }}
      >
        <Header />
      </Grid>
      <Grid
        item
        sx={{
          marginBottom: "15px",
          [theme.breakpoints.down("md")]: {
            marginBottom: "12px",
            minHeight: "60px",
          },
        }}
      >
        <Headline />
      </Grid>
      {state.appState === "ERROR" && state.error.errorType === "RATE_LIMIT" && (
        <>
          <Grid item>
            There's a limit to 3 searches per day. Thanks for your patience.
          </Grid>
        </>
      )}
      {state.appState === "ERROR" && state.error.errorType !== "RATE_LIMIT" && (
        <Grid item>
          <Button
            variant="text"
            color="secondary"
            sx={{
              backgroundColor: "#FBF5EF",
              paddingRight: "12px",
              "&:hover": {
                backgroundColor: darken("#FBF5EF", 0.04),
              },
            }}
            onClick={() => dispatch({ type: "RESTART" })}
          >
            <Grid container alignItems="center">
              <ArrowLeft size={16} />
              <Typography variant="button" sx={{ marginLeft: "6px" }}>
                Back to search
              </Typography>
            </Grid>
          </Button>
        </Grid>
      )}
      <Grid
        item
        sx={{
          position: "relative",
          marginBottom: state.appState === "QUERY" ? "30px" : 0,
          display: state.appState === "QUERY" ? "block" : "none",
        }}
      >
        <SearchBar />
      </Grid>
      {state.appState === "QUERY" && (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Grid item sx={{ marginBottom: "20px" }}>
            <img src="/images/zig-zag.svg" alt="zig zag" />
          </Grid>
          <Grow in={true}>
            <Grid
              item
              sx={{
                marginTop: "40px",
                width: { sm: "365px", xs: "100%" },
                paddingBottom: { sm: 0, xs: "100%" },
                height: { sm: "365px", xs: "0px" },
                transform: "rotate(-3deg) !important",
                background: "url(/images/house.jpg)",
                backgroundSize: "cover",
                borderRadius: "15px",
                position: "relative",
              }}
            >
              <img
                src="/images/card.svg"
                alt="report card"
                style={{
                  transform: "rotate(4deg)",
                  position: "absolute",
                  bottom: 0,
                  right: matches ? 0 : -20,
                  width: matches ? "94%" : "100%",
                  overflow: "hidden",
                  borderRadius: "10px",
                  boxShadow:
                    "0px 60.1336px 48.1069px rgba(0, 0, 0, 0.07), 0px 25.1224px 20.0979px rgba(0, 0, 0, 0.0503198), 0px 13.4316px 10.7453px rgba(0, 0, 0, 0.0417275), 0px 7.52966px 6.02373px rgba(0, 0, 0, 0.035), 0px 3.99894px 3.19916px rgba(0, 0, 0, 0.0282725), 0px 1.66405px 1.33124px rgba(0, 0, 0, 0.0196802)",
                }}
              />
            </Grid>
          </Grow>
        </Grid>
      )}
      {matches && state.appState === "FORM" && !state.loading && (
        <Grid item sx={{ marginBottom: "40px" }}>
          <StatsPanel />
        </Grid>
      )}
      {!state.loading &&
        ["FORM", "FORM_SUBMIT"].includes(state.appState) && (
          <Grid item sx={{ marginBottom: "16px", width: "100%" }}>
            <Subheadline />
          </Grid>
        )}
      {!state.loading &&
        state.appState === "FORM" &&
        state.queryResult.eligibility.overall !== "NO" && (
          <>
            <Grid item sx={{ marginBottom: "30px" }}>
              
            </Grid>
            <Grid item sx={{ marginBottom: "60px" }}>
              <LeadForm />
            </Grid>
          </>
        )}
    </Grid>
  );
};

export default LeftPanel;
