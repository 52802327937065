import * as React from "react";
import * as Sentry from "@sentry/react";

import { FeatureCollection } from "../utils/geoJson.js";

const GlobalContext = React.createContext();

// APP STATES
// QUERY
// FORM
// FORM_SUBMIT
// ERROR

const initialState = {
  appState: "QUERY",
  loading: false,
  error: null,
  queryResult: null,
  geocoderContainerRef: null,
  mapRef: null,
  geocoderResult: null,
  urlRef: null,
};

function globalReducer(state, action) {
  switch (action.type) {
    case "START_QUERY": {
      return {
        ...state,
        appState: "FORM",
        loading: true,
        geocoderResult: action.query.result,
      };
    }
    case "FINISH_PARCEL_QUERY": {
      return {
        ...state,
        queryResult: {
          type: "Parcel",
          parcel: {
            ...action.body.formattedResults,
            geometry: FeatureCollection(action.body.formattedResults.geometry),
          },
          shortAddress:
            action.body.formattedResults.attributes.ShortAddress.toLowerCase(),
          eligibility: action.body.eligibility,
          recordId: action.body.recordId,
          cityTier: action.body.cityTier,
          cityGrade: action.body.cityGrade,
          jurisdiction: action.body.jurisdiction,
        },
        appState: "FORM",
        loading: false,
      };
    }
    case "FINISH_COORDINATE_QUERY": {
      return {
        ...state,
        queryResult: {
          type: "Coordinate",
          shortAddress: `${state.geocoderResult.address} ${state.geocoderResult.text}`,
          eligibility: action.body.eligibility,
          recordId: action.body.recordId,
          cityTier: action.body.cityTier,
          cityGrade: action.body.cityGrade,
          jurisdiction: action.body.jurisdiction,
        },
        appState: "FORM",
        loading: false,
      };
    }
    case "FAIL_QUERY": {
      return {
        ...state,
        appState: "ERROR",
        loading: false,
        error: action.error,
      };
    }
    case "QUERY_ERROR": {
      return {
        ...state,
        error: action.body,
        appState: "ERROR",
        loading: false,
      };
    }
    case "RATE_ERROR": {
      return {
        ...state,
        error: action.body,
        appState: "ERROR",
        loading: false,
      };
    }
    case "RESTART": {
      Array.from(
        state.geocoderContainerRef.current.getElementsByClassName(
          "mapboxgl-ctrl-geocoder--button"
        )
      )[0].click();
      window.history.replaceState({}, document.title, "/");
      return {
        ...initialState,
        mapRef: state.mapRef,
        geocoderContainerRef: state.geocoderContainerRef,
      };
    }
    case "FORM_SUBMITTED": {
      return { ...state, loading: true, appState: "FORM_SUBMIT" };
    }
    case "FORM_SUCCESSFUL": {
      return { ...state, loading: false };
    }
    case "FORM_UNSUCCESSFUL": {
      return { ...state, loading: false, error: action.error };
    }
    case "SET_SEARCH_REF": {
      return { ...state, geocoderContainerRef: action.geocoderContainerRef };
    }
    case "SET_MAP_REF": {
      return { ...state, mapRef: action.mapRef };
    }
    case "REVEAL_FORM": {
      return {
        ...state,
        queryResult: {
          ...state.queryResult,
          eligibility: { ...state.queryResult.eligibility, overall: "YES" },
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    window.history.replaceState({}, document.title, "/");
    return false;
  }
  return true;
}

function populateInitialState(initialState) {
  const params = new URLSearchParams(document.location.search.substring(1));
  const query = params.get("query");
  const ref = params.get("ref");
  const validQuery = isJsonString(query);
  return {
    ...initialState,
    ...(query && validQuery && { queryResult: JSON.parse(query) }),
    ...(ref && { urlRef: ref }),
  };
}

const GlobalProvider = React.memo(function GlobalProvider({ children }) {
  const [state, dispatch] = React.useReducer(
    globalReducer,
    populateInitialState(initialState)
  );
  const value = { state, dispatch };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
});

function useGlobalState() {
  const context = React.useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return context;
}

async function queryAddress(dispatch, query) {
  dispatch({ type: "START_QUERY", query });
  // GA Event
  window.dataLayer.push({ event: "sb9_search" });
  if (query.result.properties.accuracy === "street") {
    dispatch({
      type: "FAIL_QUERY",
      error: {
        type: "Error",
        errorType: "STREET_QUERY",
      },
    });
  } else {
    try {
      Sentry.setContext("query", { ...query });
      // generate unique transactionId and set as Sentry tag
      const transactionId = Math.random().toString(36).substring(2, 9);
      Sentry.configureScope(function (scope) {
        scope.setTag("transaction_id", transactionId);
      });
      // fetch users's IP address
      const fetchIp = await fetch(
        "https://ipinfo.io/json?token=3304cdedd714d9"
      );
      const ip = await fetchIp.json();
      // query backend
      const response = await fetch("/api/getCoordinate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Transaction-ID": transactionId,
        },
        body: JSON.stringify({ query: { ...query, ip } }),
      });
      const body = await response.json();
      Sentry.setContext("response", { ...body });
      if (body.type === "Error") {
        dispatch({ type: "QUERY_ERROR", body });
      } else {
        if (body.type === "Parcel") {
          dispatch({ type: "FINISH_PARCEL_QUERY", body });
        } else {
          dispatch({ type: "FINISH_COORDINATE_QUERY", body });
        }
      }
    } catch (error) {
      dispatch({ type: "FAIL_QUERY", error });
    }
  }
}

async function submitLeadForm(dispatch, form) {
  dispatch({ type: "FORM_SUBMITTED" });
  // GA Event
  window.dataLayer.push({ event: "generate_lead" });
  try {
    await fetch("/api/submitLeadForm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ form }),
    });
    dispatch({ type: "FORM_SUCCESSFUL" });
  } catch (error) {
    dispatch({ type: "FORM_UNSUCCESSFUL", error });
  }
}

async function submitErrorReport({ recordId, email, feedback }) {
  try {
    await fetch("/api/submitResultError", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ recordId, email, feedback }),
    });
  } catch (error) {
    console.log(error);
  }
}

export {
  GlobalProvider,
  useGlobalState,
  queryAddress,
  submitLeadForm,
  submitErrorReport,
};
