import React from "react";
import { Grid, Typography } from "@mui/material";

import Tooltip from "./Tooltip";
import { HelpCircle } from "react-feather";

const icon = (iconType) => {
  switch (iconType) {
    case "YES":
      return "check.svg";
    case "NO":
      return "x.svg";
    case "MAYBE":
      return "flag.svg";
    default: {
      throw new Error(`Unhandled icon type: ${iconType}`);
    }
  }
};

const getCopy = (eligibilityType, eligibility) => {
  switch (eligibilityType) {
    case "residential": {
      return eligibility === "YES" ? (
        <>
          Zoned for <span style={{ whiteSpace: "nowrap" }}>SB 9</span>
        </>
      ) : eligibility === "MAYBE" ? (
        <>
          May be zoned for <span style={{ whiteSpace: "nowrap" }}>SB 9</span>
        </>
      ) : (
        <>
          Not zoned for <span style={{ whiteSpace: "nowrap" }}>SB 9</span>
        </>
      );
    }
    case "flood": {
      return eligibility === "YES" ? "Not in flood zone" : "In a flood zone";
    }
    case "historic": {
      return eligibility === "YES"
        ? "Not a historic property"
        : eligibility === "MAYBE"
        ? "May be a historic property"
        : "Historic property";
    }
    case "sqft": {
      return eligibility === "YES"
        ? "Greater than 2,400 sqft"
        : "Less than 2,400 sqft";
    }
    case "fire": {
      return eligibility === "YES" ? "Low fire risk" : "High fire risk";
    }
    case "liquefaction": {
      return eligibility === "YES"
        ? "No liquefaction risk"
        : "Liquefaction risk";
    }
    case "fault": {
      return eligibility === "YES" ? "Low earthquake risk" : "Earthquake risk";
    }
    case "urban": {
      return eligibility === "YES"
        ? "In Census defined urban area"
        : "Not an Census defined urban area";
    }
    case "coastal": {
      return eligibility === "YES"
        ? "Not in a coastal zone"
        : "In a coastal area";
    }
    case "landslide": {
      return eligibility === "YES" ? "Low landslide risk" : "Landslide risk";
    }
    default: {
      throw new Error(`Unhandled copy: ${eligibilityType}`);
    }
  }
};

const getTooltipCopy = (eligibilityType) => {
  switch (eligibilityType) {
    case "residential": {
      return (
        <>
          Local zoning data is required to determine if your lot is{" "}
          <span style={{ whiteSpace: "nowrap" }}>SB 9</span> eligible. Please
          reach out for more info.
        </>
      );
    }
    case "fault": {
      return (
        <>
          Properties that are located within delineated earthquake fault zones
          are still eligible for{" "}
          <span style={{ whiteSpace: "nowrap" }}>SB 9</span> but building in
          these zones requires specific seismic considerations
        </>
      );
    }
    case "coastal": {
      return (
        <>
          Properties that are located within a designated costal zone are still
          eligible for <span style={{ whiteSpace: "nowrap" }}>SB 9</span> but
          new buildings must comply with the California Costal Act
        </>
      );
    }
    case "liquefaction": {
      return (
        <>
          Properties that are located within liquefaction zones are still
          eligible for <span style={{ whiteSpace: "nowrap" }}>SB 9</span> but
          building in these zones requires additional seismic considerations
        </>
      );
    }
    case "landslide": {
      return (
        <>
          Properties that are located within landslide zones are still eligible
          for <span style={{ whiteSpace: "nowrap" }}>SB 9</span> but building in
          these zones requires additional foundation considerations
        </>
      );
    }
    case "flood": {
      return (
        <>
          Properties that are located within flood zones might still be eligible
          for <span style={{ whiteSpace: "nowrap" }}>SB 9</span> but depends on
          your individual municipality's flood mitigation strategies
        </>
      );
    }
    default: {
      throw new Error(`Unhandled tooltip copy: ${eligibilityType}`);
    }
  }
};

const Bullet = ({ eligibility, eligibilityType }) => {
  return (
    <Grid container>
      <Grid item>
        <img
          src={`/images/${icon(eligibility)}`}
          style={{ width: 14, height: 14, display: "block" }}
          alt="icon"
        />
      </Grid>
      <Grid item xs sx={{ display: "flex" }}>
        <Typography
          sx={{
            fontWeight: 500,
            marginLeft: "6px",
            fontSize: ".85rem",
            lineHeight: 1.2,
          }}
        >
          {getCopy(eligibilityType, eligibility)}
        </Typography>
        {eligibility === "MAYBE" && (
          <Tooltip title={getTooltipCopy(eligibilityType)}>
            <HelpCircle
              size={16}
              style={{ marginLeft: "6px", color: "#C4C4C4", display: "block" }}
            />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default Bullet;
