import React from "react";
import { Grid, Typography } from "@mui/material";

const IneligbleReason = ({ headline, description }) => {
  return (
    <Grid container spacing={1}>
      <Grid item sx={{ marginRight: "10px", marginTop: "2px" }}>
        <img src="/images/x.svg" alt="x" />
      </Grid>
      <Grid item xs container direction="column">
        <Grid item>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            {headline}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{ color: "#878787", lineHeight: 1.2, marginBottom: "4px" }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IneligbleReason;
