import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { ArrowLeft } from "react-feather";
import { darken } from "@mui/material/styles";
import { useGlobalState } from "../context/context";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Header = () => {
  const { state, dispatch } = useGlobalState();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item container alignItems="center" gap={2}>
        
      </Grid>
      {!state.loading &&
        state.appState !== "QUERY" &&
        state.appState !== "ERROR" && (
          <Grid item>
            <Button
              variant="text"
              color="secondary"
              sx={{
                backgroundColor: "#FBF5EF",
                paddingRight: "12px",
                "&:hover": {
                  backgroundColor: darken("#FBF5EF", 0.04),
                },
              }}
              onClick={() => dispatch({ type: "RESTART" })}
            >
              <ArrowLeft size={16} />
              <Typography variant="button" sx={{ marginLeft: "6px" }}>
                {!matches && "Back to "}search
              </Typography>
            </Button>
          </Grid>
        )}
    </Grid>
  );
};

export default Header;
