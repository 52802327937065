// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const lotLayer = {
  id: "data",
  type: "line",
  paint: {
    "line-dasharray": [8, 2, 2, 2],
  },
};

export const buildingLayer = {
  id: "data",
  type: "line",
};
