import React from "react";
import { InputBase, FormControl, FormHelperText } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import FormLabel from "./FormLabel";

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 15,
    position: "relative",
    backgroundColor: "rgb(245, 248, 250)",
    border: "1px solid rgb(203, 214, 226)",
    fontSize: 16,
    padding: "7px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const FormField = ({
  label,
  name,
  onChange,
  value,
  error,
  helperText,
  type,
  placeholder,
  fullWidth,
  multiline,
  rows,
}) => {
  return (
    <FormControl variant="standard" error={error} fullWidth={fullWidth}>
      {label && <FormLabel label={label} />}
      <CustomInput
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        type={type}
        placeholder={placeholder}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FormField;
