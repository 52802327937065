import React from "react";
import { Grid } from "@mui/material";

import { useGlobalState } from "../context/context";
import Bullet from "./Bullet";

const parcelBullets = [
  "residential",
  "fire",
  "liquefaction",
  "fault",
  "urban",
  "flood",
  "coastal",
  "landslide",
  "historic",
  "sqft",
];
const coordinateBullets = [
  "residential",
  "fire",
  "liquefaction",
  "fault",
  "urban",
  "flood",
  "coastal",
  "landslide",
];

const makeBullets = (state) => {
  if (state.queryResult.type === "Parcel") {
    return parcelBullets.map((bullet) => (
      <Grid item xs={12} sm={6} key={bullet}>
        <Bullet
          eligibility={state.queryResult.eligibility[bullet]}
          eligibilityType={bullet}
        />
      </Grid>
    ));
  } else {
    return (
      <Grid container spacing={0.5}>
        {coordinateBullets.map((bullet) => (
          <Grid item xs={12} sm={6} key={bullet}>
            <Bullet
              eligibility={state.queryResult.eligibility[bullet]}
              eligibilityType={bullet}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
};

const Bullets = () => {
  const { state } = useGlobalState();
  return <Grid container>{makeBullets(state)}</Grid>;
};

export default Bullets;
