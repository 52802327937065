import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// Create a theme instance.
let theme = createTheme({
  typography: {
    fontFamily: "Grosa",
    h4: {
      fontFamily: "Canela",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Canela",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "Canela",
      fontWeight: 700,
    },
  },
  palette: {
    background: {
      default: "#FFFCF9",
    },
    text: {
      primary: "#0B296F",
    },
    success: {
      main: "#99C884",
    },
    primary: {
      main: "#2576CA",
    },
    secondary: {
      main: "#93877B",
    },
    error: {
      main: "#D2472A",
    },
  },
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
                font-family: Grosa;
                font-weight: 700;
                src: url("/fonts/Grosa-Bold.otf") format("opentype");
            }
            @font-face {
                font-family: Grosa;
                font-weight: 500;
                src: url("fonts/Grosa-Medium.otf") format("opentype");
            }
            @font-face {
                font-family: Grosa;
                font-weight: 400;
                src: url("fonts/Grosa-Regular.otf") format("opentype");
            }
            @font-face {
                font-family: Canela;
                font-weight: 700;
                src: url("fonts/Canela-Bold.otf") format("opentype");
            }
            `,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
