import React, { useState, useRef, useCallback, useEffect } from "react";
import MapGL, { Source, Layer, FlyToInterpolator, Marker } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";

import { queryAddress, useGlobalState } from "../context/context";
import { lotLayer } from "../styles/map-style.js";

const bBox = [-125.5539715039, 32.0514498309, -113.7180589315, 42.7135318195];
const initialState = {
  latitude: 37.3824,
  longitude: -119.636,
  zoom: 5,
};

const MapPanel = () => {
  const [viewport, setViewport] = useState(initialState);
  const [marker, setMarker] = useState(null);
  const { state, dispatch } = useGlobalState();
  const mapRef = useRef();

  // Set map ref
  useEffect(() => {
    dispatch({ type: "SET_MAP_REF", mapRef });
    if (state.queryResult) {
      window.setTimeout(() => {
        setMarker({
          latitude: state.queryResult.center[1],
          longitude: state.queryResult.center[0],
        });
        handleGeocoderViewportChange({
          latitude: state.queryResult.center[1],
          longitude: state.queryResult.center[0],
          zoom: 18,
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update viewport
  const handleViewportChange = useCallback((newViewport) => {
    return setViewport(newViewport);
  }, []);

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = {
        transitionDuration: 3000,
        transitionInterpolator: new FlyToInterpolator(),
      };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  const handleRequest = useCallback(
    (result) => {
      setMarker({
        latitude: result.result.center[1],
        longitude: result.result.center[0],
      });
      return queryAddress(dispatch, result);
    },
    [dispatch]
  );

  const handleFilter = useCallback((result) => {
    if (
      result.context.find((context) => context.id.includes("region")).text ===
      "California"
    ) {
      return true;
    } else {
      return false;
    }
  }, []);

  const handleClear = useCallback(
    () => {
      handleGeocoderViewportChange(initialState);
      setMarker(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <MapGL
      ref={mapRef}
      {...viewport}
      width="100%"
      height="100%"
      onViewportChange={handleViewportChange}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      attributionControl={false}
      mapStyle="mapbox://styles/mapbox/streets-v11"
    >
      {state.geocoderContainerRef && (
        <Geocoder
          mapRef={mapRef}
          containerRef={
            state.geocoderContainerRef && state.geocoderContainerRef
          }
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          bbox={bBox}
          onResult={handleRequest}
          types="address"
          placeholder={"Enter an address in California"}
          zoom={18}
          filter={handleFilter}
          marker={false}
          onClear={handleClear}
        />
      )}
      {marker && (
        <Marker
          longitude={marker.longitude}
          latitude={marker.latitude}
          offsetLeft={-10}
          offsetTop={-32}
        >
          <img
            src="/images/mapbox-marker-icon.svg"
            alt="map marker icon"
            style={{ transform: "scale(1.4)" }}
          />
        </Marker>
      )}
      {state.queryResult &&
        state.queryResult.type === "Parcel" &&
        state.queryResult.parcel.geometry && (
          <Source type="geojson" data={state.queryResult.parcel.geometry}>
            <Layer {...lotLayer} />
          </Source>
        )}
    </MapGL>
  );
};

export default MapPanel;
